<template>
	<div class="container">
        <el-tabs v-model="type" type="card" @tab-click="tabClick">
            <el-tab-pane label="待复审" name="0">
                <question-info :reason_options="reason_options" :activeType="type" ref="questioninfo1" type="0"/>
            </el-tab-pane>
            <el-tab-pane label="审核通过" name="1" :lazy="true">
                <question-info :reason_options="reason_options" :activeType="type" ref="questioninfo2" type="1"/>
            </el-tab-pane>
            <el-tab-pane label="审核不通过" name="2" :lazy="true">
                <question-info :activeType="type" type="2" ref="questioninfo3" />
            </el-tab-pane>
        </el-tabs>
	</div>
</template>

<script>
    import questionInfo from './questionInfo'
	export default {
        props:['reason_options','activeType'],
		data() {
			return {
                type:'0',
			}
        },
        components:{
            questionInfo
        },
        watch:{
            activeType(val){
                if(val == 0) {
                    if(this.type == 0) {
                        this.$refs.questioninfo1.getTableData(1);
                    }else if(this.type == 1){
                        this.$refs.questioninfo2.getTableData(1);
                    } else {
                        this.$refs.questioninfo3.getTableData(1);
                    }
                }
            }
        },
		methods: {
            tabClick(e){
                this.type = e.props.name
            }
		}
	}
</script>

<style>
	.title {
		width: 100%;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow-wrap: break-word;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
