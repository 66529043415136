<template>
	<div>
		<div class="question-box">
            <div style="width:50px">提问：</div>
            <div class="q_content">
                {{detail.title}}
                <div v-if="detail.content" class="q_img_content">
                    <el-image 
                    v-for="(item,index) in detail.content.split(',')"
                    :key="index"
                    :src="filterCosImage(item)" 
                    :preview-src-list="[filterCosImage(item)]" 
                    class="image-view" 
                    fit="cover" 
                    />
                </div>
            </div>
        </div>
	</div>
</template>

<script>
	import PAGE_CONFIG from '@/utils/config'
	import { filterCosImage } from '@/utils/common.js'
	export default {
		props: ['detail'],
		data() {
			return {
				defaultUrl: PAGE_CONFIG.defaultUrl
			}
		},
		created() {
			console.log(this.detail)
		},
		methods:{
			filterCosImage
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.rich-content img {
		width: 100%;
	}
    .question-box {
        display: flex;
    }
	.cover-box {
		display: flex;
	}
    .q_img_content {
        margin-top: 10px;
    }
	.image-view {
		width: 150px;
        height: 100px;
        margin-right: 20px;
	}
</style>
