<template>
    <div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 问答审核
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
        <el-tabs v-model="type" type="card" @tab-click="tabClick">
            <el-tab-pane label="提问审核" name="0">
                <question-item :activeType="type" type="0" :reason_options="reason_options" />
            </el-tab-pane>
            <el-tab-pane label="回答审核" name="1">
                <answer-item :activeType="type" type="1" :reason_options="reason_options" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import answerItem from '@/components/review/answerItem'
import questionItem from '@/components/review/questionItem'
export default {
    name: 'review',
    components: {
        answerItem,
        questionItem
    },
    data() {
        return {
            type: '0', //0待派单 1已派单
            reason_options: [
                {
                    title: '其它',
                    id: 0
                }
            ],
          
        };
    },
    created(){
        this.getReason()
    },
    methods: {
        tabClick(e) {
            //切换type
            this.type = e.props.name
        },
         getReason() {
            this.$axios({
                url: 'admin/reason/getSelect',
                method: 'get',
                params: {
                    limit: 100,
                    type: 1
                },
            }).then((res) => {
                if (res.code == 200) {
                    this.reason_options = res.data.concat(this.reason_options)
                }
            }).catch(err => {
                console.log(err)
            });
        }
    }
}
</script>
<style scoped>

</style>
